<template>
  <!-- <div class="wares-node" @click="handleDetailClick()">
    <div class="wares-node-img">
      <Uimage :src="data.url" />
    </div>
    <div class="wares-node-moeny">
      <div class="wares-node-moeny-title">
        <span>{{ data.title }}</span>
      </div>
      <div class="wares-node-moeny-number">
        <span class="integer">{{ data.priceSection }}</span>
      </div>
    </div>
  </div> -->
   <div class="content">
            <!-- <store-wares ref="wares"></store-wares> -->
            <el-table :data="data" style="width: 100%">
<!--              <el-table-column prop="spuNum" label="产品编号"></el-table-column>-->
              <el-table-column prop="name" label="产品名称">
                <template slot-scope="scope">
                  <el-link
                    :href="
                      `#/store/${scope.row.storeId}/details/${scope.row.spuId}`
                    "
                    target="_blank"
                  >
                    <span v-html="scope.row.name"></span>
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column prop="priceSection" label="价格区间">
              </el-table-column>
              <el-table-column label="商品品牌" width="200px">
                <template slot-scope="scope">
                  <el-tag type="success">{{ scope.row.brandName }}</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="price" label="价格"> </el-table-column> -->
              <!-- <el-table-column prop="inventory" label="库存"> </el-table-column> -->
            </el-table>
          </div>
</template>

<script>
export default {
  watch: {},
  props: {
    data: Object,
  },
  data() {
    return {
      price: '',
      money: {},
    };
  },
  methods: {
    handleDetailClick() {
      this.$router.push({
        name: 'detail',
        params: {
          storeId: this.data.storeId,
          spu: this.data.spuId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wares-node {
  width: 222px;
  height: 403px;
  border: #e2ecf6 2px solid;

  &-img {
    width: 100%;
    height: 277px;
    position: relative;
    background: #e2ecf6;

    > img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
    }
  }

  &-moeny {
    width: 100%;
    padding-top: 14px;

    &-thumbnail {
      padding: 0px 14px;
      height: 44px;
      display: inline-flex;

      &-item {
        width: 44px;
        height: 44px;
        margin-left: 4px;
        position: relative;

        > img {
          width: 100%;
          height: 100%;
        }

        &:first-of-type {
          margin-left: 0px;
        }

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          bottom: 0px;
          right: 0px;
          margin: auto;
          background: transparent;
          background-repeat: no-repeat;
          background-image: url('../../assets/store/thumbnail.png');
          background-size: 100% 100%;
        }
      }
    }

    &-title {
      padding: 0px 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-number {
      padding: 0px 14px;
      color: #e67e1f;

      .integer {
        font-weight: 700;

        &:before {
          font-weight: normal;
          content: '￥';
        }
      }

      .decimal {
        &:before {
          content: '.';
        }
      }
    }

    &-evaluate {
      padding: 18px 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #275af3;

      &:before {
        color: #8c8c8c;
        content: '已有';
      }

      &:after {
        color: #8c8c8c;
        content: '人评价';
      }
    }
  }
}

</style>
