import {
  get,
  post, //{x:"",xx:"",data:{code:0,msg:"",data:obj}}
  getextra, //{code:0,msg:"",data:obj}
  postextra, //{code:0,msg:"",data:obj}
  DraftPromiseData,
  GetPromiseData, //data
  PostPromiseData, //data
  GetextraPromiseData, //data
} from '@/utils/request';

import { ConvertListToChildrenTree } from '../utils/tree';

//排序拟定
export function sort() {
  return DraftPromiseData([
    {
      name: '好评度',
      params: {
        orderPrice: '1',
        orderSaleCount: '0',
        orderCommentCount: '0',
        newGoods: '0',
      },
    },
    {
      name: '销量',
      params: {
        orderPrice: '0',
        orderSaleCount: '1',
        orderCommentCount: '0',
        newGoods: '0',
      },
    },
    {
      name: '评论数',
      params: {
        orderPrice: '0',
        orderSaleCount: '0',
        orderCommentCount: '1',
        newGoods: '0',
      },
    },
    {
      name: '新品',
      params: {
        orderPrice: '0',
        orderSaleCount: '0',
        orderCommentCount: '0',
        newGoods: '1',
      },
    },
  ]);
}
/**
 * 横菜单
 */
export function BalanceMenu(id) {
  return GetextraPromiseData(
    'f/api/frontcollectStore/getstorecategoryfirst?pageNum=1&pageSize=5',
    {
      storeId: id,
    }
  );
}

export function classifyMenu(id) {
  return new Promise((resolve, reject) => {
    GetextraPromiseData('f/api/frontcollectStore/storeCategoryList', {
      storeId: id,
    })
      .then((list) => {
        resolve(
          ConvertListToChildrenTree(list, {
            idKey: 'storeProdCatId',
            parentKey: 'parentId',
          })
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function selShopStoreInfo(id) {
  return GetextraPromiseData('f/api/frontcollectStore/selShopStoreInfo', {
    storeId: id,
  });
}

export function insertCollectStore(id, name) {
  return postextra('api/collectStore/insertCollectStore', {
    storeId: id,
    storeName: name,
  });
}

export function cancelstore(id) {
  return getextra('api/collectStore/cancelstore', {
    storeId: id,
  });
}

export function isfollowstore(storeId) {
  return GetextraPromiseData('api/collectStore/isfollowstore', {
    storeId,
  });
}

export function selHotSalesStores(storeId, orderSaleCount, size) {
  return GetextraPromiseData('api/store/selHotSalesStores', {
    storeId,
    orderSaleCount,
    page: 1,
    size: size,
  });
}

export function lookGetskusalecount() {
  return GetextraPromiseData('f/api/frontcategory/selProductLabelList', {
    code: 'CX',
  });
}

export function lookGetbrowserecordlist(id) {
  return GetextraPromiseData('api/browserecord/getbrowserecordlist');
}

export function getCommentSpuIdCount(spuId) {
  return GetextraPromiseData('f/api/frontcomment/getSpuIdCount', {
    spuId: spuId,
  });
}

export function getDecorate(storeId) {
  return getextra('f/api/frontspu/decorate', {
    storeId: storeId,
  });
}
