<template>
  <div class="block-width w-1920">
    <div class="logo w-1200">
      <div class="logo-item">
        <div class="logo-item-image" @click="handleBackHome">
          <img src="../../assets/login/logo.gif" />
          <!-- <div class="qiye">企业店铺</div> -->
        </div>
        <svg class="logo-item-store-image" height="45" @click="qiye">
          <text x="10" y="22">企业店铺</text>
        </svg>
      </div>
      <div class="logo-search">
        <!-- <div class="logo-search-type">
          <div class="text">商品</div>
        </div> -->
        <div class="left">
          <div class="shangpin">{{ shang }}</div>
          <img
            @click.stop="ShowHidden = !ShowHidden"
            class="img1"
            src="../../assets/home/xiajiantou.png"
          />
          <div class="xuanze" @click.stop="" v-if="ShowHidden">
            <div
              class="yincang"
              v-for="(item, index) in xianshi"
              :key="index"
              @click="ConBtn(index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="logo-search-input">
          <input
            v-model="query"
            placeholder="输入产品编号 产品名称 关键字"
            v-focus
            class="address"
            @keyup.enter.prevent="handleSearchStore"
          />
        </div>
        <!-- <button class="logo-search-button search-all" @click="handleSearch">
          <svg class="button-icon" height="14">
            <text x="0" y="11">搜索全站</text>
          </svg>
        </button> -->
        <button
          class="logo-search-button search-store"
          @click="handleSearchStore"
        >
          <svg class="button-icon" width="58" height="14">
            <text x="15" y="11">搜索</text>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from './mixins-default';
export default {
  mixins: [mixins],
  computed: {
    query: {
      get: function() {
        return this.params.q || '';
      },
      set: function(newValue) {
        this.params.q = newValue;
      },
    },
  },
  data() {
    return {
      params: {
        q: '',
      },
      ShowHidden: false,
      xianshi: ['全站', '店铺'],
      shang: '全站',
      searchInput: '',
    };
  },
  created() {
    this.params.q = this.$route.query.q;
    // let that = this;
    // document.onkeypress = function(e) {
    //   var keycode = document.all ? event.keyCode : e.which;
    //   if (keycode == 13) {
    //     that.handleSearchStore(); // 登录方法名
    //     return false;
    //   }
    // };
  },
  methods: {
    ConBtn(val) {
      this.ShowHidden = false;
      this.shang = this.xianshi[val];
    },
    qiye() {
      // this.$router.push({
      //   name: 'store_index',
      //   params: {
      //     sid: this.getStoreId(),
      //   },
      // });
      const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          sid: this.getStoreId(),
        },
      });
      window.open(href, '_blank');
    },
    getParams() {
      return {
        ...this.params,
      };
    },
    // handleSearch() {
    //   this.$router.push({
    //     name: 'search',
    //     query: {
    //       q: this.query,
    //     },
    //   });
    // },
    handleSearchStore() {
      if (this.shang == '店铺') {
        this.$router.tp({
          name: 'store_search',
          params: {
            storeId: this.storeId,
            q: this.query,
          },
          query: {
            ...this.getParams(),
          },
        });
      } else {
        let sp_search = this.$router.resolve({
          path: '/search',
          query: {
            q: this.query,
          },
        });
        window.open(sp_search.href, '_blank');
      }
    },
    handleBackHome() {
      this.$router.push({
        name: 'Home',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.left {
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-right: 2px solid #6b8fff;
  position: relative;
  cursor: pointer;
  font-size: 16px;

  img {
    width: 15px;
    height: 10px;
    margin-left: 20px;
  }
  .xuanze {
    width: 70px;
    height: 70px;
    background: #fff;
    position: absolute;
    left: -5px;
    top: 45px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
  }
}
.block-width {
  position: relative;
  height: 104px;
  width: 100%;
  background: #fff;

  .logo {
    width: 1237px;
    height: 100%;
    margin: auto;
    // padding: 0px 30px;
    position: relative;

    &-item {
      cursor: pointer;
      width: 279px;
      height: 60px;
      position: absolute;
      top: 22px;
      bottom: 0px;
      margin: auto;
      display: inline-flex;
      margin-left: 33px;
      &-store-image {
        width: calc(100% - 159px);
        margin: auto;

        > text {
          font-size: 20px;
          font-weight: 600;
          fill: #2659f2;
        }
      }

      &-image {
        width: 158px;
        height: 44px;
        position: relative;
        display: inline-block;

        > img {
          width: 158px;
          height: 44px;
          display: inline-block;
          position: absolute;
          top: 0px;
          bottom: 0px;
          margin: auto;
        }
      }
    }

    &-search {
      position: absolute;
      height: 39px;
      // background: #000;
      top: 0px;
      bottom: 0px;
      right: 0px;
      margin: auto;
      display: flex;
      flex-direction: row;
      border: 2px solid #6b8fff;
      &-type {
        width: 95px;
        height: 100%;
        background: transparent url('../../assets/store/search-1.png') no-repeat
          100%;
        background-size: 100% 100%;

        .text {
          position: relative;
          width: 35px;
          height: 39px;
          line-height: 39px;
          margin: auto;

          &:after {
            position: absolute;
            width: 12px;
            height: 8px;
            top: 0px;
            bottom: 0px;
            right: 0px;
            margin: auto;
            content: '';
            display: inline-block;
            // background: transparent url('../../assets/store/search-1-text.png')
            //   no-repeat 100%;
            background-size: 100% 100%;
          }
        }
      }

      &-input {
        width: 317px;
        height: 100%;

        > input {
          width: calc(100% - 40px);
          height: calc(100% - 14px);
          border: 0px;
          padding: 8px 20px;
          background: transparent;

          &:focus {
            outline: none;
          }
        }
      }

      &-button {
        width: 87px;
        height: 100%;
        background: transparent;
        background-size: 100% 100%;
        border: 0px;
        position: relative;
        cursor: pointer;
        .button-icon {
          // width: 3.75em;
          width: 58px;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          margin: auto;

          > text {
            font-size: 14px;
            fill: #fff;
          }
        }

        &:focus {
          outline: none;
        }

        &.search-all {
          background: transparent url('../../assets/store/search-3.png')
            no-repeat 100%;
        }

        &.search-store {
          background: transparent url('../../assets/store/search-4.png')
            no-repeat 100%;
        }
      }
    }
  }
}
</style>
