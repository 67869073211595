<template>
  <div class="header-menu w-1920">
    <div class="menu w-1200">
      <ul class="menu-ul">
        <li
          class="menu-li menu-item"
          v-bind:class="{ activate: activate == 'home' }"
        >
          <p class="menu-item-text" @click="handleMenuClickHome()">首页</p>
        </li>
        <!-- <li
          class="menu-li menu-item"
          v-bind:class="{ activate: activate == 'all' }"
        >
          <p class="menu-item-text" @click="handleMenuClick('all')">全部分类</p>
        </li> -->
        <li
          class="menu-li menu-item"
          v-for="meun in menuList"
          :key="meun.storeProdCatId"
          v-bind:class="{ activate: activate == meun.storeProdCatId }"
        >
          <p
            class="menu-item-text"
            @click="handleMenuClick(meun.storeProdCatId)"
          >
            {{ meun.name }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import storeDefault from './mixins-default';

import { BalanceMenu } from '../../api/store';
import { get } from '@/utils/request';
export default {
  mixins: [storeDefault],
  watch: {
    $route(to, from) {
      this.setSelectMenu(to.params.selectMenu);
    },
  },
  data() {
    return {
      activate: 'home',
      menuList: [],
      params: {},
    };
  },
  created() {
    this.setSelectMenu(
      this.$route.params.selectMenu,
      this.$route.query.prodCatId
    );
    this.$nextTick(() => {
      BalanceMenu(this.sid).then((data) => {
        this.menuList = data;
      });
      // get(
      //   'api/storeCategory/getStoreProductByCategory?pageNum=1&pageSize=5&storeId=' +
      //     this.sid +
      //     ''
      // ).then((res) => {
      //   this.menuList = res.data.data.list;
      // });
    });
  },
  methods: {
    getParams() {
      return this.params;
    },
    setStoreId(sid) {
      this.sid = sid;
    },
    setSelectHome() {
      this.setSelectMenu('home');
    },
    setSelectAll() {
      this.setSelectMenu('all');
    },
    setSelectMenu(prodCatId, params) {
      if (!params) {
        params = prodCatId;
      }
      this.params = {
        prodCatId: params,
      };
      if (prodCatId == 'home' || prodCatId == 'all') {
        this.params = {};
      }
      return (this.activate = prodCatId);
    },
    handleMenuClickHome() {
      this.setSelectHome('home');
      this.$router.push({
        name: 'store_index',
        params: {
          sid: this.getStoreId(),
        },
      });
    },
    handleMenuClick(id) {
      this.$router.push({
        name: 'store_classify',
        params: {
          sid: this.getStoreId(),
          selectMenu: this.setSelectMenu(id),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-menu {
  position: relative;
  width: 100%;
  height: 30px;
  border-top: 4px solid #fe912a;
  background: #2179ff;
  display: flex;
  justify-content: center;

  .menu {
    margin-top: -1px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    .menu-ul {
      display: inline-block;
      list-style: none;
      height: 100%;
      margin: 0px;
      padding: 0px;

      .menu-li {
        display: inline-block;
        height: auto;
        width: auto;
        cursor: pointer;
      }
    }

    .menu-item {
      color: #ffffff;

      &:first-of-type {
        .menu-item-text {
          &:before {
            width: 0px;
          }
        }
      }

      &.activate {
        background: #f0eeef;
        color: #4298c6;

        & + .menu-li {
          .menu-item-text {
            &:before {
              width: 0px;
            }
          }
        }

        .menu-item-text {
          &:before {
            width: 0px;
          }
        }
      }

      .menu-item-text {
        position: relative;
        margin: 0px;
        padding: 6.5px 5px;
        font-size: 14px;

        &:before {
          content: '';
          position: absolute;
          width: 1px;
          height: 13px;
          background: #fff;
          left: 0px;
          top: 0px;
          bottom: 0px;
          margin: auto;
        }
      }
    }
  }
}
</style>
