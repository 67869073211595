<template>
  <component ref="grid" :is="tab">
    <slot v-for="(data, index) in gridList" :data="gridList[index]"></slot>
  </component>
</template>

<script>
import { get, post, getextra, GetextraPromiseData } from '@/utils/request';

function execFunction(data) {
  if (typeof data === 'function') {
    return execFunction(data());
  }
  return data || {};
}

export default {
  props: {
    tab: String,
    src: String,
    params: Object | Function,
    search: Object | Function,
    data: Array,
  },
  data() {
    return {
      gridUrl: '',
      gridParams: {},
      gridSearch: {},
      gridList: [],
    };
  },
  created() {
    this.$parent.setComponentGrid(this);
  },
  methods: {
    init(params, search, page) {
      var url = this.src ? this.src : this.gridUrl;
      var params = {
        ...execFunction(this.params),
        ...execFunction(this.search),
        ...execFunction(this.gridParams),
        ...execFunction(this.gridSearch),
        ...execFunction(page),
      };
      if (!url) {
        this.gridList = this.data;
        return;
      }
      getextra(url, params, {
        timeout: 1000 * 60 * 2,
      })
        .then((result) => {
          var data = result['data'];
          this.$parent
            .getComponentPage()
            .setTotal((data && data['total']) || 0);
          this.gridList = (data && data['list']) || [];
        })
        .catch((err) => {
          this.gridList = this.data;
          console.error(err);
        });
    },
    setParams(params) {
      this.params = params;
    },
    setSearch(search) {
      this.gridSearch = search;
    },
    setUrl(url) {
      this.url = url;
    },
  },
};
</script>
