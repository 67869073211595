import grid from './grid';
import page from './page';

function execFunction(data) {
  if (typeof data === "function") {
    return execFunction(data());
  }
  return data || {};
}

export default {
  components: {
    grid,
    page
  },
  data() {
    return {
      componentGrid: {},
      componentPage: {},
      params: {},
      search: {}
    };
  },
  methods: {
    setComponentGrid(grid) {
      this.componentGrid = grid;
    },
    setComponentPage(page) {
      this.componentPage = page;
    },
    getComponentGrid(grid) {
      return this.componentGrid;
    },
    getComponentPage(page) {
      return this.componentPage;
    },
    initGrid(params, search) {
      this.componentGrid.init({
        ...execFunction(params),
        ...execFunction(this.params),
      }, {
        ...execFunction(search),
        ...execFunction(this.search)
      }, execFunction(this.componentPage.getParams));
    },
    setUrl(url) {
      this.componentGrid.setUrl(url);
    },
  }
}