
export function ConvertListToChildrenTree(list,parentId,childrenName,config){
  var TreeArray=[];
  var __parentId = typeof parentId!="object" && parentId || 0;
  var __childrenName = typeof childrenName!="object" && childrenName || "children";
  var __config = {
    idKey:"id",
    parentKey:"pid",
  }

  if(typeof parentId=="object"){
    __config=parentId;
  }else if(typeof childrenName=="object"){
    __config=childrenName;
  }

  if(typeof config=="object"){
    __config={
      ...config
    }
  }

  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if(element[__config.parentKey]==__parentId){
      var data={...element};
      data[__childrenName]=ConvertListToChildrenTree(list,element[__config.idKey],__childrenName,__config)
      TreeArray.push(data);
    }
  }
  return TreeArray;
}
