function paramsBeneficiate(s){
  return {
    ...s
  }
}
export default {
  watch: {
    $route(to, from) {
      this.initStoreId();
    }
  },
  data() {
    return {
      storeId: ""
    };
  },
  created() {
    this.initStoreId();
  },
  methods: {
    initStoreId(){
      var params= paramsBeneficiate(this.$route.params);
      if(params.hasOwnProperty("storeId")){
        this.setStoreId(params["storeId"]);
        return;
      }
      this.setStoreId("")
    },
    setStoreId(storeId) {
      return this.storeId = storeId;
    },
    getStoreId() {
      return this.storeId;
    }
  }
}
