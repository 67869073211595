<template>
  <component ref="pager" :is="tab">
    <el-pagination 
      background
      :small.sync="small" 
      :layout.sync="layout"
      :page-sizes.sync="pageSizeList" 
      :page-size.sync="pageSize"
      :current-page.sync="currentPage" 
      :hide-on-single-page.sync="hideSinglePage"
      :total.sync="total" 
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange" 
      ></el-pagination>
  </component>
</template>

<script>
  export default {
    props: {
      tab: String,
    },
    data() {
      return {
        small:false,
        layout: "sizes, prev, pager, next, jumper",
        hideSinglePage: true,
        pageSizeList:[1,3,6,10,20,100,120,200,300],
        pageSize: 10,
        currentPage: 1,
        total: 0
      };
    },
    created(){
      this.$parent.setComponentPage(this);
    },
    methods: {
      getParams() {
        return {
          size: this.pageSize,
          pageSize: this.pageSize,
          page: this.currentPage,
          pageNum: this.currentPage
        }
      },
      setTotal(total){
        this.total=total;
      },
      handleSizeChange() {
        this.$parent.initGrid();
      },
      handleCurrentChange() {
        this.$parent.initGrid();
      }
    }
  }
</script>