<template>
  <div class="header-image w-1920" v-if="imgUrl != null && imgUrl != ''">
    <Uimage :src="imgUrl" />
  </div>
</template>

<script>
  import storeDefault from './mixins-default';
  import {
    getDecorate
  } from '../../api/store';
  export default {
    mixins: [storeDefault],
    props: {
      // sid:String|Number,
      // src:String,
      // occupy:Boolean
    },
    data() {
      return {
        imgUrl: "",
      };

    },
    created() {
      getDecorate(this.storeId).then((msg) => {
        if (msg.code == 0) {
          this.imgUrl = msg.data.imgBeijing;
        } else {
          this.imgUrl = "";
        }

      });
    }
  }
</script>

<style lang="scss" scoped>
  .header-image {
    position: relative;
    width: 100%;
    height: 114.5px;

    .header-msg {
      width: 100%;
      height: 114.5px;
      line-height: 114px;
      text-align: center;
      background: #6b6d6c;
      color: #fff;
    }

    >img {
      width: 100%;
      height: 100%;
    }
  }
</style>