<template>
  <div class="infochip w-1920">
    <div class="w-1200-r">
      <div class="info">
        <div class="info-item store-name">
          <span>{{ form && form.name }}</span>
          <div class="store-name-hover">
            <div class="store-name-info rate">
              <div class="store-name-info-item">
                <div class="store-name-info-item-text">
                  <span>店铺等级</span>
                </div>
                <div class="store-name-info-item-content ">
                  <el-rate disabled :value="4"></el-rate>
                </div>
              </div>
            </div>

            <div class="store-name-info score line">
              <div class="store-name-info-item">
                <div class="store-name-info-item-text">
                  <span>用户评价</span>
                </div>
                <div class="store-name-info-item-content">
                  <span class="high">8.31</span>
                </div>
              </div>

              <div class="store-name-info-item">
                <div class="store-name-info-item-text">
                  <span>物流履约</span>
                </div>
                <div class="store-name-info-item-content">
                  <span class="in">8.31</span>
                </div>
              </div>

              <div class="store-name-info-item">
                <div class="store-name-info-item-text">
                  <span>售后服务</span>
                </div>
                <div class="store-name-info-item-content">
                  <span class="low">8.31</span>
                </div>
              </div>
            </div>

            <div class="store-name-info store-info">
              <div class="store-name-info-item">
                <div class="store-name-info-item-text">
                  <span>店铺名称</span>
                </div>
                <div class="store-name-info-item-content ">
                  <span>{{ form && form.name }}</span>
                </div>
              </div>
              <br />
              <div class="store-name-info-item">
                <div class="store-name-info-item-text">
                  <span>违规信息</span>
                </div>
                <div class="store-name-info-item-content ">
                  <span>无</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-item customer-service">
          <a rel="nofollow"
            href="https://cschat-ccs.aliyun.com/index.htm?tntInstId=o532_d88&scene=SCE00010277"
            target="_blank"
            >联系客服</a>
        </div>
        <div class="info-item " @click="handleSubscribe">
          <div class="special-item-text">
            <div class="guanzhu" v-if="subscribe">
              <img src="../../assets/details/follow_02.png" />
            </div>
            <div class="guanzhu" v-else>
              <img src="../../assets/store/subscribe.png" />
            </div>
            <span>{{ subscribe ? '已关注' : '关注' }}</span>
          </div>
        </div>
        <!-- <div class="info-item sign-in">
        <span>签到</span>
      </div>
      <div class="info-item store-vip">
        <span>品牌会员</span>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import store_Default from './mixins-default';
import { isLogin } from '../../utils/public';
import {
  selShopStoreInfo,
  insertCollectStore,
  cancelstore,
  isfollowstore,
} from '@/api/store';
export default {
  inject: ['reload'],
  mixins: [store_Default],
  data() {
    return {
      form: {},
      subscribe: false,
    };
  },
  created() {
    if (isLogin()) {
      isfollowstore(this.storeId).then((data) => {
        this.subscribe = !!eval('(' + data + ')');
      });
    }
    selShopStoreInfo(this.storeId).then((data) => {
      this.form = data;
    });
  },
  methods: {
    handleSubscribe() {
      if (this.subscribe) {
        cancelstore(this.storeId).then((x) => {
          if (x.code == 0) {
            this.subscribe = false;
          }
        });
      } else {
        insertCollectStore(this.storeId, this.form.name).then((x) => {
          if (x.code == 0) {
            this.subscribe = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
a {
  text-decoration: none;
  color: #000;
}
.infochip {
  .el-rate {
    > .el-rate__item {
      > .el-rate__icon {
        margin-right: 0px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.special-item-text {
  display: flex;
  align-items: center;
  width: 80px;
  span {
    margin-left: 5px;
  }
}
.guanzhu {
  display: flex;
  align-items: center;
}
.infochip {
  width: 100%;
  height: 37px;
  background: #f6f4f5;
  position: relative;

  .info {
    position: absolute;
    right: 20px;
    display: flex;
    justify-self: flex-end;

    &-item {
      margin-left: 20px;
      height: 100%;
      line-height: 37px;
      cursor: pointer;
    }

    .store-name {
      font-weight: 700;
      position: relative;
      cursor: default;
      color: #327399;

      &-hover {
        display: none;
        position: absolute;
        width: 250px;
        height: 231px;
        background: #fff;
        top: 100%;
        left: 0px;
        z-index: 1;
        padding: 20px;

        .store-name-info {
          font-weight: normal;
          width: 100%;

          &.line {
            padding: 7px 0px;
            border-top: 1px solid #ececec;
            border-bottom: 1px solid #ececec;
          }

          &.rate {
            .store-name-info-item-content {
              padding: 5px;
            }
          }

          &.score {
            .store-name-info-item-content {
              padding-left: 5px;
              color: #2957f3;
            }

            .high {
              &:after {
                content: '高';
                margin-left: 10px;
              }
            }

            .in {
              &:after {
                content: '中';
                margin-left: 10px;
              }
            }

            .low {
              &:after {
                content: '低';
                margin-left: 10px;
              }
            }
          }

          &.store-info {
            .store-name-info-item-content {
              height: 30px;
              padding-left: 5px;
              overflow: hidden;
            }
          }

          &-item {
            display: inline-flex;
            flex-direction: row;
            color: #000;

            &-text {
              width: 80px;
            }

            &-content {
              width: calc(100% - 80px);
            }
          }
        }
      }

      &:hover {
        .store-name-hover {
          display: inline-block;
        }
      }
    }

    .subscribe,
    .sign-in,
    .customer-service,
    .store-vip {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        width: 17px;
        height: 17px;
        left: 0px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        background: transparent;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .store-vip {
      &:before {
        background-image: url('../../assets/store/store-vip.png');
      }
    }

    .sign-in {
      &:before {
        background-image: url('../../assets/store/sign-in.png');
      }
    }

    .customer-service {
      &:before {
        background-image: url('../../assets/store/customer-service.png');
      }
    }

    .subscribe {
      &.active {
        > span {
          &:before {
            content: '已';
          }
        }

        &:before {
          background-image: url('../../assets/store/subscribe_active.png');
        }
      }

      &:before {
        background-image: url('../../assets/store/subscribe.png');
      }
    }
  }
}
</style>
