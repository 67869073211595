<template>
  <div class="store-wares">
    <!-- <grid
      src="api/storeCategory/getStoreProductByCategory"
      :params="getParams"
      tab="div"
      class="store-wares-content"
    >
      <store-wares-node
        slot-scope="scope"
        :data="scope.data"
      ></store-wares-node>
    </grid> -->
    <div class="content">
      <el-table :data="data" style="width: 100%">
        <el-table-column prop="name" label="产品名称">
          <template slot-scope="scope">
            <el-link
              :href="`#/store/${scope.row.storeId}/details/${scope.row.spuId}`"
              target="_blank"
            >
              <span v-html="scope.row.name"></span>
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="priceSection" label="价格区间">
        </el-table-column>
        <el-table-column label="商品品牌" width="200px">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.brandName }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <page tab="div" class="store-wares-page"></page> -->
    <div class="fenye" v-if="data != ''">
      <el-pagination
        @current-change="pagechange"
        :current-page="page.current"
        style="text-align: right;margin-top: 20px"
        background
        :total="page.total"
        :page-size="page.size"
        layout="prev, pager, next, jumper, ->, total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import store_wares_node from './store-wares-node';
import gridmixins from '@/grid/index';
import store_Default from './mixins-default';
import { get, post } from '@/utils/request';
export default {
  watch: {
    $route(to, from) {
      this.initGrid();
    },
  },
  mixins: [gridmixins, store_Default],
  data() {
    return {
      data: [],
      storeId: '',
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      q:''
    };
  },
  components: {
    'store-wares-node': store_wares_node,
  },
  created() {
    // this.$nextTick(() => {
    //   this.initGrid();
    // });
    this.getlist(this.getStoreId(),this.$parent.getParams().q);
    this.storeId = this.getStoreId();
    this.q = this.$parent.getParams().q
  },
  methods: {
    getParams() {
      return {
        storeId: this.getStoreId(),
        ...this.$parent.getParams(),
      };
    },
    getlist(e,q) {
      get(
        'api/storeCategory/getStoreProductByCategory?storeId=' +
          e +
          '&q='+q+'&pageNum=1&pageSize=10'
      ).then((res) => {
        this.data = res.data.data.list;
        this.page.total = res.data.data.total;
      });
    },
    pagechange(p) {
      this.page.current = p;
      get(
        'api/storeCategory/getStoreProductByCategory?pageNum=' +
          p +
          '&q='+this.q+'&pageSize=10&storeId=' +
          this.storeId
      ).then((res) => {
        this.data = res.data.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.store-wares {
  width: 100%;
  padding-bottom: 1px;

  &-content {
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 222px);
    grid-gap: 5px 0px;
  }

  &-page {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 50px 0px;
  }
}
</style>
